import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const CityPicker = ({ onCitySelect }) => {
  const [cities, setCities] = useState([]);
  const [displayHebrew, setDisplayHebrew] = useState(false);
  const [options, setOptions] = useState([]);

  // Load cities from JSON file on mount
  useEffect(() => {
    const loadCities = async () => {
      const citiesData = await import('./citiesInIsrael.json');
      setCities(citiesData.default);
      setOptions(
        citiesData.default
          .map((city) => ({
            label: displayHebrew ? city.name : city.english_name,
            key: `${city.english_name}-${city.long}-${city.latt}`, // Unique key
          }))
          .filter((flt) => flt.label)
      );
    };
    loadCities();
  }, []);

  // Update options when language display changes
  useEffect(() => {
    setOptions(
      cities
        .map((city) => ({
          label: displayHebrew ? city.name : city.english_name,
          key: `${city.name}${city.english_name}-${city.long}-${city.latt}`, // Unique key
        }))
        .filter((flt) => flt.label)
    );
  }, [displayHebrew, cities]);

  const handleToggleDisplay = () => {
    setDisplayHebrew(!displayHebrew);
  };

  const handleCitySelect = (event, value) => {
    const selectedCity = cities.find((city) => (displayHebrew ? city.name : city.english_name) === value?.label);
    if (selectedCity) {
      onCitySelect('location', selectedCity.english_name);
    }
  };

  return (
    <div className='city-picker' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.key}
          onChange={handleCitySelect}
          renderInput={(params) => <TextField {...params} label='Select a city' variant='outlined' />}
          fullWidth
          slotProps={{ style: { width: 'fit-content' } }}
          sx={{ width: 300 }}
        />
        <Button variant='contained' color='dark' onClick={handleToggleDisplay}>
          {displayHebrew ? 'EN' : 'HE'}
        </Button>
      </div>
    </div>
  );
};

export default CityPicker;
