import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from './common/Progress';
import { chackValidName } from '../UserIntake/formHelpers';
import Welcome from './common/Welcome';
import { apiAction, generalSet } from '../../store/generalSlice';
import { useParams } from 'react-router-dom';
import Spinner from '../../layout/Spinner';
import Error from './common/Error';

const Intake1 = () => {
  const { intake_id } = useParams();
  const dispatch = useDispatch();

  const [welcome, setWelcome] = useState(false);
  const [form, setForm] = useState({ occupation: [] });
  const [error, setError] = useState(undefined);

  const { width, generalIntake } = useSelector((state) => state.general);

  const isMobile = width <= 768;

  const welcomeProps = {
    title: 'היי עדי',
    content:
      'תודה על השיחה שהייתה לנו, יש לנו כמה דברים להשלים לפני שנתחיל.\nהשאלות אולי ישמעו קצת רנדומליות אך הן נועדו לכוון אותנו ובכך הותאמו באופן אישי רק לאחר שנפגשנו.\n\nתודה על שיתוף הפעולה,\nפנקס.',
  };

  const handleForm = (field, value) => {
    setForm((form) => {
      return { ...form, [field]: value };
    });
  };

  const handleNext = (stepData) => {
    if (stepData.validate) {
      setError(undefined);
      const validation = stepData.validate(form, stepData.id);
      if (validation?.error) {
        return setError(validation.error);
      }
    }
    setStep(step + 1);
    setProgress(100 / (stepsData.length / (step + 1)));
  };

  const stepsData = [
    {
      id: 'intake1',
      type: 'textarea',
      inputType: 'text',
      title: 'אם היית צריכה לבחור ערך אחד, למחלקת הנוער במטה יהודה. איזה ערך היית בוחרת שהוא המייצג ביותר?',
      icon: <i className='fa-regular fa-star'></i>,
    },
    {
      id: 'intake2',
      type: 'textarea',
      inputType: 'text',
      title: 'האם יש אירגון, קהילה או כל מודל אחר, מהווה עבורכן השראה לאיך האירגון שלכן יכול להתנהל?',
      icon: <i className='fa-solid fa-list-check'></i>,
    },
    {
      id: 'intake3',
      type: 'textarea',
      inputType: 'text',
      title: 'לו היו לכם אינסוף משאבים וזמן, מה היה הדבר הראשון שהייתם משקיעים בו? ולמה?',
      icon: <i className='fa-solid fa-hand-holding-dollar'></i>,
    },
    {
      id: 'intake4',
      type: 'textarea',
      inputType: 'text',
      title:
        'תנו שלוש תכונות המתארות את דמות של רכז הנוער האולטימטיבי? קחו דוגמאות מרכזי נוער שאתן מכירות בלי לציין את שמו. או דמות דמיונית.',
      icon: <i className='fa-solid fa-child-reaching'></i>,
    },
    {
      id: 'intake5',
      type: 'textarea',
      inputType: 'text',
      title: 'מה לדעתך האלמנט שיגרום לרכז הנוער להרגיש שהוא משמעותי ומוביל את חזון המחלקה?',
      icon: <i className='fa-solid fa-square-person-confined'></i>,
    },
    {
      id: 'intake6',
      type: 'textarea',
      inputType: 'text',
      title: 'אם מחלקת הנוער, כאירגון, היה איבר בגוף האדם איזה איבר הוא היה ולמה? קחו זמן לחשוב על השאלה הזו.',
      icon: <i className='fa-solid fa-x-ray'></i>,
    },
  ];
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(100 / (stepsData.length / step));
  const rtl = true;

  useEffect(() => {
    dispatch(
      apiAction({
        route: 'generalIntake/getGeneralIntakeStatus',
        reduxField: 'generalIntake',
        id: intake_id,
      })
    );
  }, []);

  if (generalIntake?.error === 'FINISHED') {
    return <Error msg={'תודה עדי - אצור קשר בקרוב'} rtl={rtl} isMobile={isMobile} />;
  }
  return generalIntake?.active ? (
    <>
      {!welcome ? (
        <Welcome
          rtl={rtl}
          isMobile={isMobile}
          setWelcome={setWelcome}
          title={welcomeProps.title}
          content={welcomeProps.content}
        />
      ) : (
        <div className='container mt-5' style={{ direction: rtl ? 'rtl' : null, textAlign: rtl ? 'right' : 'left' }}>
          <div
            className='col-lg-12 d-flex justify-content-center order-1 order-lg-2 hero-img'
            style={isMobile ? {} : { width: '35%', marginLeft: '32%', marginRight: '32%' }}
            data-aos='zoom-in'
            data-aos-delay='200'
          >
            <img
              src='/assets/img/hero-img.png'
              className='img-fluid animated'
              alt='main_image'
              style={{ width: '15rem' }}
            />
          </div>
          <div className='row justify-content-center align-items-center mt-5 mb-2'>
            <div className='col-md-12 d-flex justify-content-center'>
              <h1>כמה שאלות לפני שמתחילים</h1>
            </div>
          </div>
          <div data-aos='fade-up'>
            <ProgressBar progress={progress} />
          </div>
          <div className='row justify-content-center align-items-center'>
            {stepsData.map((stepData, i) => {
              const stepNumber = i + 1;
              if (stepNumber !== step) {
                return null;
              }
              return (
                <Fragment key={stepData.id}>
                  <div data-aos='fade-up' className='col-md-12 d-flex justify-content-center mt-5'>
                    <h5>{stepData.icon}</h5>
                  </div>
                  <div data-aos='fade-up' className='col-md-12 d-flex justify-content-center'>
                    <h5>{stepData.title}</h5>
                  </div>
                  {error ? (
                    <div data-aos='fade-up' className='col-md-12 d-flex justify-content-center'>
                      <h6 className='text-danger'>{error}</h6>
                    </div>
                  ) : null}
                  <div data-aos='fade-up' className='col-md-6 d-flex justify-content-center mt-2'>
                    {stepData.type === 'input' ? (
                      <input
                        autoFocus
                        className='form-control'
                        style={{ textAlign: 'center' }}
                        defaultValue={form[stepData.id]}
                        onChange={(e) => handleForm(stepData.id, e.target.value)}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleNext(stepData) : null)}
                      />
                    ) : null}
                    {stepData.type === 'textarea' ? (
                      <textarea
                        autoFocus
                        className='form-control'
                        rows={4}
                        defaultValue={form[stepData.id]}
                        onChange={(e) => handleForm(stepData.id, e.target.value)}
                      />
                    ) : null}
                    {stepData.type === 'customInput' && stepData.customInput ? stepData.customInput : null}
                  </div>
                  <div className='col-md-12 d-flex justify-content-center mt-2'>
                    {stepNumber !== 1 ? (
                      <button
                        className='btn btn-outline-dark'
                        onClick={() => {
                          setError(undefined);
                          setStep(step - 1);
                          setProgress(100 / (stepsData.length / (step - 1)));
                        }}
                      >
                        חזרה
                      </button>
                    ) : null}
                    {stepsData.length === stepNumber ? (
                      <button
                        className='btn btn-dark'
                        onClick={() => {
                          dispatch(
                            apiAction({
                              route: 'generalIntake/updateGeneralIntake',
                              id: intake_id,
                              intakeResults: form,
                            })
                          );
                          dispatch(
                            generalSet({
                              reduxField: 'generalIntake',
                              value: { error: 'FINISHED' },
                            })
                          );
                        }}
                      >
                        שליחה
                      </button>
                    ) : (
                      <button
                        className='btn btn-dark'
                        onClick={() => {
                          handleNext(stepData);
                        }}
                      >
                        הבא
                      </button>
                    )}
                    {stepData.skippable ? (
                      <button
                        className='btn btn-secondary'
                        onClick={() => {
                          setError(undefined);
                          setStep(step + 1);
                          setProgress(100 / (stepsData.length / (step + 1)));
                        }}
                      >
                        דלגי
                      </button>
                    ) : null}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  ) : (
    <Spinner />
  );
};

export default Intake1;
