import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, loadUser } from '../../store/authSlice';
import { reverseUrl } from '../../utils/helpers';
import Spinner from '../../layout/Spinner';

const Login = () => {
  const dispatch = useDispatch();
  const { redirect } = useParams();

  const { user, signData } = useSelector((state) => state.auth);
  const { width } = useSelector((state) => state.general);
  const [formData, setFormData] = useState({});
  const [waitAwhile, setWaitAwhile] = useState(false);
  const isMobile = width <= 768;

  const handleFormData = (value, key) => {
    setFormData((state) => {
      return { ...state, [key]: value };
    });
  };

  const loggedIn = user && user._id;
  useEffect(() => {
    dispatch(loadUser(user?._id));
    setTimeout(() => {
      setWaitAwhile(true);
    }, 3000);
  }, []);
  console.log({ user });
  if (signData) return <Navigate to={`/loginuser/${signData}`} />;
  if (loggedIn) {
    return <Navigate to={`${reverseUrl(redirect) || '/profile'}`} />;
  }
  return waitAwhile ? (
    <div className='container mt-5'>
      <div
        className='col-lg-12 d-flex justify-content-center order-1 order-lg-2 hero-img'
        style={isMobile ? {} : { width: '35%', marginLeft: '32%' }}
        data-aos='zoom-in'
        data-aos-delay='200'
      >
        <img src='/assets/img/hero-img.png' className='img-fluid animated' alt='main_image' />
      </div>
      <div className='row d-flex justify-content-center p-4 mt-4'>
        <div className='col-md-4'>
          <div className='form mb-4'>
            <input
              type='email'
              id='form2Example1'
              className='form-control'
              onChange={(e) => handleFormData(e.target.value, 'email')}
            />
            <label className='form-label'>Email address</label>
          </div>

          <div className='form-outline mb-4'>
            <input
              type='password'
              id='form2Example2'
              className='form-control'
              onChange={(e) => handleFormData(e.target.value, 'password')}
            />
            <label className='form-label'>Password</label>
          </div>
          {user === 'SKIFJHG83' ? (
            <div className='d-flex justify-content-center mb-2'>
              <h5 className='text-danger'>Access Denied</h5>
            </div>
          ) : null}
          <button
            type='button'
            className='btn btn-outline-dark btn-block'
            onClick={() => dispatch(loginUser(formData))}
          >
            Sign in
          </button>
        </div>
      </div>
      <div className='row d-flex justify-content-center p-2 mt-2'>
        <Link to='/passwordResetRequest'>Forgot password</Link>
      </div>
    </div>
  ) : (
    <Spinner width='150' />
  );
};

export default Login;
