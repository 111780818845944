import React from 'react';

const Welcome = (props) => {
  const { rtl, setWelcome, isMobile, title, content } = props;
  return (
    <div className='container mt-5' style={{ direction: rtl ? 'rtl' : null, textAlign: rtl ? 'right' : 'left' }}>
      <div
        className='col-lg-12 d-flex justify-content-center order-1 order-lg-2 hero-img'
        style={isMobile ? {} : { width: '35%', marginLeft: '32%', marginRight: '32%' }}
        data-aos='zoom-in'
        data-aos-delay='200'
      >
        <img
          src='/assets/img/hero-img.png'
          className='img-fluid animated'
          alt='main_image'
          style={{ width: '15rem' }}
        />
      </div>
      <div className='row justify-content-center align-items-center mt-5 mb-2'>
        <div className='col-md-12 d-flex justify-content-center'>
          <h1>{title}</h1>
        </div>
      </div>
      <div className='row justify-content-center align-items-center mt-5 mb-2'>
        <div className='col-md-12 d-flex justify-content-center'>
          <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{content}</p>
        </div>
      </div>

      <div className='row justify-content-center align-items-center mt-5 mb-2'>
        <div className='col-md-12 d-flex justify-content-center'>
          <button
            className='btn btn-dark'
            onClick={() => {
              setWelcome(true);
            }}
          >
            הבא
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
