import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import generalSlice from './generalSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    general: generalSlice,
  },
});
