export const localToken = localStorage.getItem('from_client_local_token');
export const authRoute = process.env.REACT_APP_AUTH_ROUTE;
export const apiServerURI = process.env.REACT_APP_VECTOR_SERVER_URI;

export const occupationOptions = [
  { id: 'production', title: 'Production', faIcon: 'fa-solid fa-user-tie' },
  { id: 'marketing', title: 'Marketing', faIcon: 'fa-solid fa-comments-dollar' },
  { id: 'visualArt', title: 'Visual art', faIcon: 'fa-solid fa-masks-theater' },
  { id: 'graphicDesign', title: 'Graphic design', faIcon: 'fa-solid fa-icons' },
  { id: 'photography', title: 'Photography', faIcon: 'fa-solid fa-camera-retro' },
  { id: 'artisticManagement', title: 'Artistic Management', faIcon: 'fa-solid fa-stamp' },
  { id: 'soundAndAmplification', title: 'Sound and Amplification', faIcon: 'fa-solid fa-headphones' },
  { id: 'projectManagement', title: 'Project Management', faIcon: 'fa-solid fa-list-check' },
  { id: 'music', title: 'Music', faIcon: 'fa-solid fa-music' },
  { id: 'writing', title: 'Writing', faIcon: 'fa-solid fa-paragraph' },
  { id: 'lighting', title: 'Lighting', faIcon: 'fa-regular fa-lightbulb' },
  { id: 'programming', title: 'Programming', faIcon: 'fa-solid fa-code' },
];
