import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ProgressBar from './Progress';
import Password from './Password';
import { chackValidBirthDate, chackValidName, checkPassword, checkValidEmail } from './formHelpers';
import DatePicker from './DatePicker';
import PhoneNumberInput from './PhoneNumberInput';
import SocialMediaLinks from './SocialMediaLinks';
import CityPicker from './CityPicker';
import OccupationPicker from './OccupationPicker';
import { createUser, loadUser } from '../../store/authSlice';

const UserIntake = () => {
  const dispatch = useDispatch();

  const [navigateToLogin, setNavigateToLogin] = useState(false);
  const [form, setForm] = useState({ occupation: [] });
  const [error, setError] = useState(undefined);

  const { width } = useSelector((state) => state.general);
  const { user, signData } = useSelector((state) => state.auth);
  console.log({ user, form });
  const isMobile = width <= 768;

  const handleForm = (field, value) => {
    setForm((form) => {
      return { ...form, [field]: value };
    });
  };

  const handleNext = (stepData) => {
    if (stepData.validate) {
      setError(undefined);
      const validation = stepData.validate(form, stepData.id);
      if (validation?.error) {
        return setError(validation.error);
      }
    }
    setStep(step + 1);
    setProgress(100 / (stepsData.length / (step + 1)));
  };

  const stepsData = [
    {
      id: 'firstName',
      type: 'input',
      inputType: 'text',
      title: 'First Name',
      validate: chackValidName,
      icon: <i className='fa-regular fa-user'></i>,
    },
    {
      id: 'lastName',
      type: 'input',
      inputType: 'text',
      title: 'Last Name',
      validate: chackValidName,
      icon: <i className='fa-regular fa-user'></i>,
    },
    {
      id: 'email',
      type: 'input',
      inputType: 'text',
      title: 'Email',
      validate: checkValidEmail,
      icon: <i className='fa fa-envelope' aria-hidden='true'></i>,
    },
    {
      id: 'location',
      type: 'customInput',
      title: 'Where are you from?',
      customInput: <CityPicker onCitySelect={handleForm} />,
      icon: <i className='fa-solid fa-location-dot'></i>,
      skippable: true,
    },
    {
      id: 'phone',
      type: 'customInput',
      title: 'Phone Number',
      customInput: <PhoneNumberInput value={form.phone} field='phone' onChange={handleForm} />,
      icon: <i className='fa-solid fa-phone'></i>,
      skippable: true,
    },
    {
      id: 'dateOfBirth',
      type: 'customInput',
      inputType: 'text',
      title: 'Date of Birth',
      validate: chackValidBirthDate,
      customInput: <DatePicker field='dateOfBirth' selectedDate={form.dateOfBirth} onDateChange={handleForm} />,
      icon: <i className='fas fa-birthday-cake'></i>,
      skippable: true,
    },
    {
      id: 'occupation',
      type: 'customInput',
      title: 'What do you do?',
      customInput: <OccupationPicker selected={form.occupation} onSelect={handleForm} />,
      icon: <i className='fas fa-tasks'></i>,
      skippable: true,
    },
    {
      id: 'aboutMe',
      type: 'textarea',
      inputType: 'text',
      title: 'About Me',
      icon: <i className='fa-regular fa-user'></i>,
      skippable: true,
    },
    {
      id: 'socialMediaLinks',
      type: 'customInput',
      inputType: 'text',
      title: 'Social Media Links',
      customInput: <SocialMediaLinks form={form} onChange={handleForm} />,
      skippable: true,
    },
    {
      id: 'password',
      type: 'customInput',
      inputType: 'text',
      title: 'Password',
      customInput: <Password form={form} handleForm={handleForm} />,
      validate: checkPassword,
      icon: <i className='fa-solid fa-key'></i>,
    },
    {
      id: 'finish',
      type: 'customInput',
      validate: checkPassword,
      customInput: (
        <div className='btn-group-vertical'>
          <h1>Create Membership</h1>
          <h6>
            {form.firstName} {form.lastName} | {form.email}
          </h6>
        </div>
      ),
    },
  ];
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(100 / (stepsData.length / step));

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  if (signData) return <Navigate to={`/loginuser/${signData}`} />;

  // if (navigateToLogin) {
  //   return <Navigate to='/login/SDFWE_ORMTLDR' />;
  // }

  return (
    <div className='container mt-5'>
      <div
        className='col-lg-12 d-flex justify-content-center order-1 order-lg-2 hero-img'
        style={isMobile ? {} : { width: '35%', marginLeft: '32%' }}
        data-aos='zoom-in'
        data-aos-delay='200'
      >
        <img src='assets/img/hero-img.png' className='img-fluid animated' alt='main_image' />
      </div>
      <div className='row justify-content-center align-items-center mt-5 mb-2'>
        <div className='col-md-12 d-flex justify-content-center'>
          <h1>Become a Member</h1>
        </div>
      </div>
      <div data-aos='fade-up'>
        <ProgressBar progress={progress} />
      </div>
      <div className='row justify-content-center align-items-center'>
        {stepsData.map((stepData, i) => {
          const stepNumber = i + 1;
          if (stepNumber !== step) {
            return null;
          }
          return (
            <Fragment key={stepData.id}>
              <div data-aos='fade-up' className='col-md-12 d-flex justify-content-center mt-5'>
                <h5>{stepData.icon}</h5>
              </div>
              <div data-aos='fade-up' className='col-md-12 d-flex justify-content-center'>
                <h5>{stepData.title}</h5>
              </div>
              {error ? (
                <div data-aos='fade-up' className='col-md-12 d-flex justify-content-center'>
                  <h6 className='text-danger'>{error}</h6>
                </div>
              ) : null}
              <div data-aos='fade-up' className='col-md-6 d-flex justify-content-center mt-2'>
                {stepData.type === 'input' ? (
                  <input
                    autoFocus
                    className='form-control'
                    style={{ textAlign: 'center' }}
                    defaultValue={form[stepData.id]}
                    onChange={(e) => handleForm(stepData.id, e.target.value)}
                    onKeyDown={(e) => (e.key === 'Enter' ? handleNext(stepData) : null)}
                  />
                ) : null}
                {stepData.type === 'textarea' ? (
                  <textarea
                    autoFocus
                    className='form-control'
                    rows={4}
                    defaultValue={form[stepData.id]}
                    onChange={(e) => handleForm(stepData.id, e.target.value)}
                  />
                ) : null}
                {stepData.type === 'customInput' && stepData.customInput ? stepData.customInput : null}
              </div>
              <div className='col-md-12 d-flex justify-content-center mt-2'>
                {stepNumber !== 1 ? (
                  <button
                    className='btn btn-outline-dark'
                    onClick={() => {
                      setError(undefined);
                      setStep(step - 1);
                      setProgress(100 / (stepsData.length / (step - 1)));
                    }}
                  >
                    Back
                  </button>
                ) : null}
                {stepsData.length === stepNumber ? (
                  <button
                    className='btn btn-dark'
                    onClick={() => {
                      dispatch(createUser(form));
                      setNavigateToLogin(true);
                    }}
                  >
                    Finish
                  </button>
                ) : (
                  <button
                    className='btn btn-dark'
                    onClick={() => {
                      handleNext(stepData);
                    }}
                  >
                    Next
                  </button>
                )}
                {stepData.skippable ? (
                  <button
                    className='btn btn-secondary'
                    onClick={() => {
                      setError(undefined);
                      setStep(step + 1);
                      setProgress(100 / (stepsData.length / (step + 1)));
                    }}
                  >
                    Skip
                  </button>
                ) : null}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default UserIntake;
