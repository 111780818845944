import React from 'react';

const SocialMediaLinks = ({ facebook, instagram, tiktok, website, onChange }) => {
  return (
    <div className='row'>
      {/* Facebook Link */}
      <div className='col-md-6'>
        <div className='mb-2'>
          <label htmlFor='facebook' className='form-label'>
            <i className='fab fa-facebook-f me-2'></i> Facebook
          </label>
          <input
            type='url'
            className='form-control'
            id='facebook'
            placeholder='Enter Facebook URL'
            value={facebook}
            onChange={(e) => onChange('facebook', e.target.value)}
          />
        </div>

        {/* Instagram Link */}
        <div className='mb-2'>
          <label htmlFor='instagram' className='form-label'>
            <i className='fab fa-instagram me-2'></i> Instagram
          </label>
          <input
            type='url'
            className='form-control'
            id='instagram'
            placeholder='Enter Instagram URL'
            value={instagram}
            onChange={(e) => onChange('instagram', e.target.value)}
          />
        </div>
      </div>
      <div className='col-md-6'>
        {/* TikTok Link */}
        <div className='mb-2'>
          <label htmlFor='tiktok' className='form-label'>
            <i className='fab fa-tiktok me-2'></i> TikTok
          </label>
          <input
            type='url'
            className='form-control'
            id='tiktok'
            placeholder='Enter TikTok URL'
            value={tiktok}
            onChange={(e) => onChange('tiktok', e.target.value)}
          />
        </div>

        {/* Personal Website Link */}
        <div className='mb-2'>
          <label htmlFor='website' className='form-label'>
            <i className='fas fa-globe me-2'></i> Personal Website
          </label>
          <input
            type='url'
            className='form-control'
            id='website'
            placeholder='Enter Personal Website'
            value={website}
            onChange={(e) => onChange('website', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaLinks;
