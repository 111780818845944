import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './index.css';
import Layout from './layout/Layout';
import Index from './layout/Index';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import LoginUser from './components/auth/LoginUser';
import Profile from './components/Profile/Profile';
import PrivateRoute from './layout/PrivateRoute';
import UserIntake from './components/UserIntake/UserIntake';
import PasswordResetRequest from './components/auth/PasswordResetRequest';
import PasswordReset from './components/auth/PasswordReset';
import Intake1 from './components/Intakes.js/Intake1';

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/login/:redirect',
        element: <Login />,
      },
      {
        path: '/loginuser/:token',
        element: <LoginUser />,
      },
      // {
      //   path: '/register',
      //   element: <Register />,
      // },
      {
        path: '/passwordResetRequest',
        element: <PasswordResetRequest />,
      },
      {
        path: '/passwordReset/:reset_url',
        element: <PasswordReset />,
      },
      {
        path: '/',
        element: <Index />,
      },
      {
        path: '/profile',
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
      {
        path: '/userintake',
        element: <UserIntake />,
      },
      {
        path: '/intake1/:intake_id',
        element: <Intake1 />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);
