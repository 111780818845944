import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Password from '../UserIntake/Password';
import { useParams } from 'react-router-dom';
import { checkPassword } from '../UserIntake/formHelpers';
import { apiAction, generalSet } from '../../store/generalSlice';
import Spinner from '../../layout/Spinner';

const PasswordReset = () => {
  const { reset_url } = useParams();
  const dispatch = useDispatch();

  const [form, setForm] = useState({});
  const [error, setError] = useState(undefined);

  const { passwordReset, loadingPasswordReset, width } = useSelector((state) => state.general);

  const isMobile = width <= 768;

  const handleForm = (field, value) => {
    setForm((form) => {
      return { ...form, [field]: value };
    });
  };

  const initPasswordReset = () => {
    setError(undefined);
    const passwordValidation = checkPassword(form);
    if (passwordValidation?.error) {
      setError(passwordValidation.error);
    }
    dispatch(generalSet({ reduxField: 'loadingPasswordReset', value: true }));
    dispatch(
      apiAction({
        route: 'auth/passwordReset',
        reduxField: 'passwordReset',
        resetUrl: reset_url,
        password: form.password,
        password2: form.password2,
        setLoading: 'loadingPasswordReset',
      })
    );
  };

  if (passwordReset?.success) {
    window.location.href = '/login/SDFWE_ORMTLDR';
  }

  console.log({ form, passwordReset, loadingPasswordReset, error });
  return (
    <div className='container mt-5'>
      <div
        className='col-lg-12 d-flex justify-content-center order-1 order-lg-2 hero-img'
        style={isMobile ? {} : { width: '35%', marginLeft: '32%' }}
        data-aos='zoom-in'
        data-aos-delay='200'
      >
        <img src='/assets/img/hero-img.png' className='img-fluid animated' alt='main_image' />
      </div>
      <div className='row d-flex justify-content-center p-4 mt-4 ' data-aos='fade-up'>
        <Password form={form} handleForm={handleForm} />
      </div>
      {error && !form.generatePassword ? (
        <div className='row d-flex justify-content-center ' data-aos='fade-up'>
          <h6 className='text-danger'>{error}</h6>
        </div>
      ) : null}
      {passwordReset?.error ? (
        <div className='row d-flex justify-content-center ' data-aos='fade-up'>
          <h6 className='text-danger'>{passwordReset.error}</h6>
        </div>
      ) : null}
      <div className='row d-flex justify-content-center p-2 mt-2 '>
        {loadingPasswordReset ? (
          <Spinner />
        ) : (
          <button
            className='btn btn-outline-dark'
            onClick={() => {
              initPasswordReset();
            }}
          >
            Reset Password
          </button>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
