import { occupationOptions } from '../../utils/constants';

const OccupationPicker = ({ selected, onSelect }) => {
  const handleChange = (optionId) => {
    if (selected.includes(optionId)) {
      return onSelect(
        'occupation',
        selected.filter((flt) => flt !== optionId)
      );
    }
    return onSelect('occupation', [...selected, optionId]);
  };

  return (
    <div className='container'>
      <div className='d-flex justify-content-center flex-wrap mb-2'>
        {occupationOptions.map((option) => (
          <button
            key={option.id}
            className={`btn btn-sm btn${selected.includes(option.id) ? '' : '-outline'}-dark m-1`}
            onClick={() => handleChange(option.id)}
          >
            <small>
              <i className={option.faIcon}></i> {option.title}
            </small>
          </button>
        ))}
      </div>
    </div>
  );
};

export default OccupationPicker;
