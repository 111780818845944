export const reverseUrl = (url) => {
  try {
    if (!url) {
      return '/';
    }
    const urlArray = url.split('SDFWE_ORMTLDR');
    return urlArray.join('/');
  } catch (error) {
    return '/';
  }
};

export const setAuthToken = (token) => {
  localStorage.setItem('from_client_local_token', token);
};

export const parseUrl = (url) => {
  try {
    if (!url) {
      return '/';
    }
    const urlArray = url.split('/');
    return urlArray.join('SDFWE_ORMTLDR');
  } catch (error) {
    return '/';
  }
};

export const validateRegisterForm = (form) => {
  let errors = [];
  const { firstName, lastName, email, password, password2 } = form;
  if (!firstName || !lastName || !email || !password || !password2) {
    errors = [...errors, { id: 'firstName', msg: 'Please provide a valid name' }];
  }
  if (password !== password2) {
    errors = [...errors, { id: 'password', msg: 'Please chack your password' }];
  }
  return errors.length ? { errors } : { success: true };
};

export const parseDate = (date) => {
  try {
    const newDate = new Date(date);
    if (newDate) {
      return newDate.toDateString();
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const passwordGenerator = (passwordLength) => {
  if (typeof passwordLength !== 'number' || passwordLength < 1) {
    return '';
  }

  try {
    const capLet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const lowLet = capLet
      .map((letter) => (typeof letter === 'string' ? letter.toLowerCase() : null))
      .filter((letter) => typeof letter === 'string')
      .filter((letter) => letter);
    const signs = ['!', '@', '#', '$', '%', '^', '&', '*', '!'];
    const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const arrayOfArrays = [capLet, lowLet, signs, digits];

    let password = '';

    for (let i = 0; i < passwordLength; i++) {
      const array = arrayOfArrays[getRandomInt(0, arrayOfArrays.length - 1)];
      const letter = array[getRandomInt(0, array.length - 1)];
      password += letter;
    }
    return password;
  } catch (error) {
    console.log(error);
    return '';
  }
};
