import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiAction } from '../../store/generalSlice';
import Spinner from '../../layout/Spinner';
import { occupationOptions } from '../../utils/constants';
import { parseDate } from '../../utils/helpers';
import { logout, logoutUser } from '../../store/authSlice';

const Profile = () => {
  const dispatch = useDispatch();
  const { user, loggedOut } = useSelector((state) => state.auth);
  const { fullUser } = useSelector((state) => state.general);
  console.log({ user, fullUser });

  useEffect(() => {
    dispatch(apiAction({ reduxField: 'fullUser', route: 'auth/getFullUser', id: user._id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const forbiddenFields = ['_id'];
  if (loggedOut) {
    window.location.reload();
  }
  return Array.isArray(fullUser?.userIntakeFields) ? (
    <div className='container my-5'>
      <div className='row border-bottom'>
        <div className='col-md-4'>
          <h1>
            <i className='fa-solid fa-id-card'></i>
          </h1>
        </div>
        <div className='col-md-4 d-flex justify-content-center'>
          <h1 className='capitalize mr-2'>{fullUser.firstName}</h1>
          <h1 className='capitalize'>{fullUser.lastName}</h1>
        </div>
        <div className='col-md-4 d-flex justify-content-center'></div>
      </div>
      <div className='row mt-4'>
        {fullUser.userIntakeFields
          .filter((flt) => !forbiddenFields.includes(flt.id))
          .map((field) => {
            const EditButton = () => (
              <div className='d-flex flex-row-reverse'>
                {field.editable ? (
                  <button className='btn btn-sm btn-outline-dark'>
                    <i className='fas fa-edit'></i>
                  </button>
                ) : (
                  <button className='btn btn-sm btn-outline-secondary disabled'>
                    <i className='fas fa-edit'></i>
                  </button>
                )}
              </div>
            );

            // If field is "occupation"
            if (field.id === 'occupation' && Array.isArray(fullUser.occupation)) {
              return (
                <div key={field.id} className='col-md-6 border p-2' data-aos='fade-up'>
                  <EditButton />
                  {field.faClass ? <i className={field.faClass}></i> : null} <b>{field.title}</b>:{' '}
                  <div className='row'>
                    {fullUser.occupation.map((occ) => {
                      const occupation = occupationOptions.filter((flt) => flt.id === occ)[0];
                      if (!occupation) {
                        return null;
                      }
                      return (
                        <span className='border p-1 ml-2' key={occ}>
                          <i className={occupation.faIcon}></i> {occupation.title}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            }
            // If field is "dateOfBirth"
            if (field.id === 'dateOfBirth' && parseDate(fullUser.dateOfBirth)) {
              return (
                <div key={field.id} className='col-md-6 border p-2' data-aos='fade-up'>
                  <EditButton />
                  {field.faClass ? <i className={field.faClass}></i> : null} <b>{field.title}</b>:{' '}
                  {parseDate(fullUser.dateOfBirth)}
                </div>
              );
            }
            // If field is anything else
            return (
              <div key={field.id} className='col-md-6 border p-2' data-aos='fade-up'>
                <EditButton />
                {field.faClass ? <i className={field.faClass}></i> : null} <b>{field.title}</b>: {fullUser[field.id]}{' '}
              </div>
            );
          })}
        <div key='logoutUser' className='col-md-6 border d-flex justify-content-center p-2' data-aos='fade-up'>
          <button className='btn btn-outline-dark mt-4 mb-4' onClick={() => dispatch(logout())}>
            Logout
          </button>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Profile;
