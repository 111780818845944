import React, { useState } from 'react';
import { passwordGenerator } from '../../utils/helpers';

const Password = (props) => {
  const { form, handleForm } = props;
  const [viewPassword, setViewPassword] = useState(false);

  return form?.generatePassword ? (
    <div className='btn-group'>
      <input
        className='form-control'
        placeholder='Password...'
        type={viewPassword ? undefined : 'password'}
        style={{ textAlign: 'center' }}
        disabled
        defaultValue={form.password}
        onChange={(e) => handleForm('password', e.target.value)}
      />
      <button
        className={`btn btn-sm btn${viewPassword ? '' : '-outline'}-dark`}
        title='View password'
        onClick={() => {
          setViewPassword(!viewPassword);
        }}
      >
        <i className='fa-solid fa-eye'></i>{' '}
      </button>
      <button
        className='btn btn-sm btn-outline-dark'
        title='Copy to clipboard'
        onClick={() => {
          navigator.clipboard.writeText(form.password);
        }}
      >
        <i className='fa-solid fa-copy'></i>
      </button>
    </div>
  ) : (
    <div className='btn-group-vertical'>
      <button
        className='btn btn-outline-success'
        onClick={() => {
          handleForm('generatePassword', true);
          const password = passwordGenerator(10);
          handleForm('password', password);
          handleForm('password2', password);
        }}
      >
        Generate Password
      </button>
      <input
        className='form-control'
        placeholder='Password...'
        type='password'
        style={{ textAlign: 'center' }}
        defaultValue={form.password}
        onChange={(e) => handleForm('password', e.target.value)}
      />
      <input
        className='form-control'
        placeholder='Confirm Password...'
        type='password'
        style={{ textAlign: 'center' }}
        defaultValue={form.password2}
        onChange={(e) => handleForm('password2', e.target.value)}
      />
    </div>
  );
};

export default Password;
