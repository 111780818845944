import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { authRoute, localToken } from '../utils/constants';

const initialState = {};

const headers = {
  Authorization: `Baerer ${localToken}`,
};

export const Slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    generalSet: (state, action) => {
      if (action.payload.setLoading) {
        state[action.payload.setLoading] = false;
      }
      state[action.payload.reduxField] = action.payload.value;
    },

    addToArray: (state, action) => {
      if (action.payload.setLoading) {
        state[action.payload.setLoading] = false;
      }
      state[action.payload.reduxField].push(action.payload.value);
    },
  },
});

export const apiAction = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/${formData.route}`, { ...formData }, { headers });
    dispatch(generalSet({ reduxField: formData.reduxField, value: response.data }));
  } catch (err) {
    throw new Error(err);
  }
};

export const { generalSet, addToArray } = Slice.actions;

export default Slice.reducer;
