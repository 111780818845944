import React from 'react';
import { Link } from 'react-router-dom';

const GetStarted = () => {
  return (
    <section id='hero' className='d-flex align-items-center' style={{ backgroundColor: 'white' }}>
      <div className='container'>
        <div className='row'>
          <div
            className='col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1'
            data-aos='fade-up'
            data-aos-delay='200'
          >
            <h1 className='text-dark'>O10TA - Authentic aprroach to life</h1>
            <h2 style={{ color: 'gray' }}>
              We empower organizations to uncover the authentic nature of their people, aligning actions with values for
              meaningful growth. Authenticity is a journey of self-discovery and reflection, unlocking potential and
              fostering connection.
            </h2>
            {/* <div className='d-flex justify-content-end'>
              <Link className='btn btn-lg btn-outline-dark mr-1' to='/login/SDFWE_ORMTLDRprofile'>
                Sign In
              </Link>
              <Link className='btn btn-lg btn-outline-primary' to='/register'>
                Sign Up
              </Link>
            </div> */}
          </div>
          <div className='col-lg-6 order-1 order-lg-2 hero-img' data-aos='zoom-in' data-aos-delay='200'>
            <img src='assets/img/hero-img.png' className='img-fluid animated' alt='' style={{ width: '25rem' }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
