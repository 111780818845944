import React, { useState } from 'react';

const DatePicker = ({ field, selectedDate, onDateChange }) => {
  // If `selectedDate` is provided, use it as initial state; otherwise, default to an empty string.
  const [date, setDate] = useState(selectedDate || '');

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);
    onDateChange && onDateChange(field, newDate); // Call onDateChange callback if provided
  };

  return (
    <div className='mb-3'>
      <input type='date' value={date} className='form-control' onChange={handleDateChange} />
    </div>
  );
};

export default DatePicker;
