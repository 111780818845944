import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberInput = ({ value, field, onChange }) => {
  return (
    <div className='phone-input'>
      <PhoneInput
        country={'il'} // Default country code; can be set to any country by ISO2 code
        onChange={(e) => onChange(field, e)}
        placeholder='Enter phone number'
        enableSearch
        value={value}
        preferredCountries={['us', 'gb', 'il', 'de']} // Customize preferred countries
      />
    </div>
  );
};

export default PhoneNumberInput;
