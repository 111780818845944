import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseUrl } from '../utils/helpers';

export default function PrivateRoute({ children }) {
  const user = useSelector((state) => state.auth.user);

  if (!user || !user._id) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={`/login/${parseUrl(window.location.pathname)}`} />;
  }

  // authorized so return child components
  return children;
}
