import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiServerURI, localToken } from '../utils/constants';

const initialState = {
  loggedIn: false,
  doneLoading: false,
  user: {},
  signData: undefined,
};

const headers = {
  Authorization: `Baerer ${localToken}`,
};

export const Slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user || action.payload.error || { error: 'SDFSDH2390847' };
      state.signData = action.payload.signData;
      state.loggedIn = true;
      state.doneLoading = true;
    },
    logoutUser: (state, action) => {
      state.user = null;
      state.signData = null;
      state.loggedIn = false;
      state.doneLoading = true;
      state.loggedOut = true;
    },
  },
});

export const loadUser = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiServerURI}/auth/getUser`, {}, { headers });

    dispatch(setUser(response.data));
  } catch (err) {
    return {};
  }
};

export const createUser = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiServerURI}/auth/createUser`, { ...formData }, { headers });
    dispatch(setUser(response.data));
  } catch (err) {
    throw new Error(err);
  }
}; //

export const loginUser = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiServerURI}/auth/loginUser`, { ...formData }, { headers });

    dispatch(setUser(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem('from_client_local_token');
    dispatch(logoutUser({ user: {} }));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setUser, logoutUser } = Slice.actions;

export default Slice.reducer;
