import { Box, ClickAwayListener, FormControl, InputLabel, MenuItem, MenuList, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Header = () => {
  const { user } = useSelector((state) => state.auth);
  return user?._id ? (
    <header id='header'>
      <div className='container d-flex align-items-center'>
        <h1 className='logo mr-auto d-flex align-items-center'>
          <img src='/assets/img/logo.png' alt='Formrally.com' className='img-fluid mr-1' />
          <Link to='/'>
            O<span className='text-secondary'>10</span>TA
          </Link>
        </h1>

        <nav className='nav-menu d-none d-lg-block'>
          <Link
            to={'/profile'}
            style={{ fontSize: '1.5rem' }}
            className='btn btn-lg btn-outline-light border-0'
            title='Account'
          >
            <i className='fa-regular fa-user'></i>
          </Link>
        </nav>
      </div>
    </header>
  ) : null;
};

export default Header;
