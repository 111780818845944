import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../components/header/Header';
import { generalSet } from '../store/generalSlice';

// import Footer from './Footer';

const Layout = () => {
  const dispatch = useDispatch();
  dispatch(generalSet({ reduxField: 'width', value: window.innerWidth }));
  const setWidth = (width) => {
    dispatch(generalSet({ reduxField: 'width', value: width }));
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default Layout;
