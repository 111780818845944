import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress }) => {
  // Ensure the progress is between 0 and 100
  const safeProgress = Math.min(100, Math.max(0, progress));

  return (
    <div className='progress'>
      <div
        className='progress-bar'
        role='progressbar'
        style={{ width: `${safeProgress}%`, backgroundColor: 'gray' }}
        aria-valuenow={safeProgress}
        aria-valuemin='0'
        aria-valuemax='100'
      >
        {/* {Math.floor(safeProgress)}% */}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
