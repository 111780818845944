export const chackValidName = (form, stepId) => {
  if (typeof form !== 'object') {
    return { error: 'Please provide a valid name' };
  }
  const value = form[stepId];
  if (!value || typeof value !== 'string' || !value.length) {
    return { error: 'Please provide a valid name' };
  }
  return { success: true };
};

export const chackValidBirthDate = (form, stepId) => {
  const date = new Date(form[stepId]);
  console.log({ date, range: Date.now() - new Date(date) });

  if (typeof form !== 'object') {
    return { error: 'Please provide a valid birth date' };
  }
  const value = form[stepId];
  if (!value || typeof value !== 'string' || !value.length) {
    return { error: 'Please provide a valid birth date' };
  }
  // 568156464619 = 18 years in milliseconds

  if (Date.now() - new Date(date) < 568156464619) {
    return { error: 'You have to be at least 18 to become a member' };
  }

  return { success: true };
};

export const checkPassword = (form) => {
  if (typeof form !== 'object') {
    return { error: 'Please provide a valid password' };
  }
  const { password, password2 } = form;
  if (typeof password !== 'string' || typeof password2 !== 'string') {
    return { error: 'Please provide a valid password' };
  }
  if (password.length < 6) {
    return { error: 'Please choose a longer password (minimum 6 characters)' };
  }
  if (password !== password2) {
    return { error: 'Passwords must match' };
  }
  return { success: true };
};

export const checkValidEmail = (form) => {
  if (typeof form !== 'object') {
    return { error: 'Please provide a valid email' };
  }
  const { email } = form;
  if (typeof email !== 'string') {
    return { error: 'Please provide a valid email' };
  }
  const isValid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  if (!isValid) {
    return { error: 'Please provide a valid email' };
  }
  return { success: true };
};
