import React from 'react';
import Contact from '../components/contact/Contact';
import About from '../components/about/About';
import GetStarted from '../components/getStarted/GetStarted';
import Services from '../components/services/Services';

const Index = () => {
  return (
    <div>
      <GetStarted />
      {/* <Services />
      <About />
      <Contact /> */}
    </div>
  );
};

export default Index;
