import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidEmail } from '../UserIntake/formHelpers';
import { apiAction, generalSet } from '../../store/generalSlice';
import Spinner from '../../layout/Spinner';

const PasswordResetRequest = () => {
  const dispatch = useDispatch();
  const { passwordResetRequest, loadingPasswordResetRequest, width } = useSelector((state) => state.general);
  const [email, setEmail] = useState();
  const [error, setError] = useState(undefined);
  const isMobile = width <= 768;

  const initPasswordResetRequest = (email) => {
    setError(undefined);
    if (checkValidEmail({ email })?.error) {
      return setError('Please provide a valid email');
    }

    dispatch(generalSet({ reduxField: 'loadingPasswordResetRequest', value: true }));
    dispatch(
      apiAction({
        route: 'auth/passwordResetRequest',
        reduxField: 'passwordResetRequest',
        email,
        setLoading: 'loadingPasswordResetRequest',
      })
    );
  };
  console.log({ passwordResetRequest });
  return (
    <div className='container mt-5'>
      <div
        className='col-lg-12 d-flex justify-content-center order-1 order-lg-2 hero-img'
        style={isMobile ? {} : { width: '35%', marginLeft: '32%' }}
        data-aos='zoom-in'
        data-aos-delay='200'
      >
        <img src='assets/img/hero-img.png' className='img-fluid animated' alt='main_image' />
      </div>
      <div className='row d-flex justify-content-center p-4 mt-4' data-aos='fade-up'>
        {passwordResetRequest?.success ? (
          <div className='col-md-4'>
            <h4>Please check your email inbox for further instructions</h4>
          </div>
        ) : (
          <div className='col-md-4' data-aos='fade-up'>
            <div className='form mb-4'>
              <label className='form-label'>What's your email?</label>
              <input
                type='email'
                className='form-control'
                defaultValue={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={(e) => (e.key === 'Enter' ? initPasswordResetRequest(email) : null)}
              />
            </div>

            {error ? (
              <div className='d-flex justify-content-center mb-2'>
                <h5 className='text-danger'>{error}</h5>
              </div>
            ) : null}
            {loadingPasswordResetRequest ? (
              <Spinner />
            ) : (
              <button
                type='button'
                className='btn btn-outline-dark btn-block'
                onClick={() => {
                  initPasswordResetRequest(email);
                }}
              >
                Reset Password
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordResetRequest;
